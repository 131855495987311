// const server = import.meta.env.REACT_APP_API || 'https://dsp-api.be.wtf/api'; // TESTapiapi
// const server = import.meta.env.REACT_APP_API || 'https://ui-api-proxy.bidease.com/api'; // PROD
const server = import.meta.env.REACT_APP_API || 'http://localhost:5001/api'; // localhost back

if (import.meta.env.DEV && server === 'https://ui-api-proxy.bidease.com/api') {
  console.log('%c>>PRODUCTION API<<', 'color: red; background: yellow; font-size: 20px');
}

const versionOne = `${server}/v1/`;

const API = {
  adminTokenCreateRequestDto: versionOne + 'admin/token/create',
  adminOrganizationStatsRequestDto: versionOne + 'admin/organization/stats',
  adminOrganizationStatsDetails: versionOne + 'admin/organization/stats/details',
  adminGlobalSearch: versionOne + 'admin/search',
  adminUsersListRequestDto: versionOne + 'admin/user/list',

  // user
  userAuthRequestDto: versionOne + 'user/auth',
  userSessionVerifyRequestDto: versionOne + 'user/session/verify',
  userRemindPasswordRequestDto: versionOne + 'user/password/remind',
  userUpdatePasswordRequestDto: versionOne + 'user/password/update',
  userUpdateRequestDto: versionOne + 'user/update',
  // userRegisterEmailRequestDto: versionOne + 'user/register/email',
  userProfileRequestDto: versionOne + 'user/profile',
  userPasswordRestoreRequestDto: versionOne + 'user/password/set',
  userAddSubUserRequestDto: versionOne + 'user/add',
  userTokenDisposeRequestDto: versionOne + 'user/token/dispose',
  usersListRequestDto: versionOne + 'user/list', // #al
  userUpdateSubUserRequestDto: versionOne + 'user/update/subuser', // #al
  adminUserUpdateSubUserRequestDto: versionOne + 'admin/user/update/subuser', // #admn
  // [[##END]] user

  // campaign
  campaignCreateRequestDto: versionOne + 'campaign/create',
  campaignUpdateRequestDto: versionOne + 'campaign/update',
  campaignListStatsRequestDto: versionOne + 'campaign/stats/list',
  campaignTitlesListRequestDto: versionOne + 'campaign/titles/list',
  campaignDetailsListRequestDto: versionOne + 'campaign/details/list',
  campaignStatsGroupingRequestDto: versionOne + 'campaign/stats/group',
  campaignCopyRequestDto: versionOne + 'campaign/copy',
  campaignChangeSetRequestDto: versionOne + 'campaign/changeset',
  campaignStatsGroupRequestDto: versionOne + 'campaign/stats/time-series',
  campaignStatsRejectGroupRequestDto: versionOne + 'campaign/stats/rejects/time-series',
  // [[##END]] campaign

  // service
  dictionaryListRequestDto: versionOne + 'dictionary/list',
  goalStatsListRequestDto: versionOne + 'goal/stats/list',
  // [[##END]] service

  // rekl
  reklListRequestDto: versionOne + 'rekl/list',
  reklCreateRequestDto: versionOne + 'rekl/create',
  reklUpdateRequestDto: versionOne + 'rekl/update',
  userReklStatusSetRequestDto: versionOne + 'user/rekl/state/set',
  userReklFavoriteSetRequestDto: versionOne + 'user/rekl/favorite',
  // [[##END]] rekl

  // segments
  segmentListRequestDto: versionOne + 'segment/list',
  segmentCreateRequestDto: versionOne + 'segment/create',
  segmentFetchRequestDto: versionOne + 'segment/fetch',
  segmentDeleteRequestDto: versionOne + 'segment/delete',
  // [[##END]] segments

  // organization
  organizationUpdateRequestDto: versionOne + 'organization/update',
  organizationCreateRequestDto: versionOne + 'organization/create',
  organizationPredictorRequestDto: versionOne + 'organization/predictor',
  organizationBillingListRequestDto: versionOne + 'organization/payment/list',
  organizationPaymentCreateRequestDto: versionOne + 'organization/payment/create',
  // [[##END]] organization

  // markup
  markupListRequestDto: versionOne + 'markup/list', // #al
  adminMarkupListRequestDto: versionOne + 'admin/markup/list', // #admn
  markupUpdateRequestDto: versionOne + 'markup/update', // #al
  adminMarkupUpdateRequestDto: versionOne + 'admin/markup/update', // #admn
  markupCopyRequestDto: versionOne + 'markup/copy', // #al
  adminMarkupCopyRequestDto: versionOne + 'admin/markup/copy', // #admn
  markupCreateRequestDto: versionOne + 'markup/create', // #al
  adminMarkupCreateRequestDto: versionOne + 'admin/markup/create', // #admn
  markupRenderRequestDto: versionOne + 'markup/render', // #al
  // [[##END]] markup

  // resource
  resourceUploadRequestDto: versionOne + 'resource/upload',
  resourceListRequestDto: versionOne + 'resource/list',
  // [[##END]] resource

  // creative
  creativeListRequestDto: versionOne + 'creative/list',
  creativeCreateRequestDto: versionOne + 'creative/create',
  creativeUpdateRequestDto: versionOne + 'creative/update',
  creativeCopyRequestDto: versionOne + 'creative/copy',
  creativeApproveRequestListRequestDto: versionOne + 'creative/requests/list',
  creativeApproveRequestDto: versionOne + 'creative/approve',
  // [[##END]] creative

  // history
  historyLogListRequestDto: versionOne + 'historylog/list',
  // [[##END]] history

  // product
  productListRequestDto: versionOne + 'product/list',
  productCreateRequestDto: versionOne + 'product/create',
  productCopyRequestDto: versionOne + 'product/copy',
  productUpdateRequestDto: versionOne + 'product/update',
  productStatsListRequestDto: versionOne + 'product/stats/list',
  // [[##END]] product

  // bundle
  bundleSaveRequestDto: versionOne + 'bundle/save',
  bundleListRequestDto: versionOne + 'bundle/list',
  // [[##END]] bundle

  // report
  reportTaskPrototypeCreateRequestDto: versionOne + 'report-task-prototype/create',
  reportTaskPrototypeListRequestDto: versionOne + 'report-task-prototype/list',
  reportTaskPrototypeUpdateRequestDto: versionOne + 'report-task-prototype/update',
  reportTaskListRequestDto: versionOne + 'report-task/list',
  // [[##END]] report

  // models
  mLearnTaskListRequestDto: versionOne + 'mlearn_task/list',
  mLearnTaskUpdateRequestDto: versionOne + 'mlearn_task/update',
  mLearnTaskCreateRequestDto: versionOne + 'mlearn_task/create',
  // [[##END]] models

  // predictor
  predictorModelListRequestDto: versionOne + 'predictor_model/list',
  // [[##END]] predictor

  // reklBundle
  reklBundleListRequestDto: versionOne + 'rekl-bundle/list',
  reklBundleSaveRequestDto: versionOne + 'rekl-bundle/save',
  // [[##END]] reklBundle

  billingStripeCreateRequestDto: versionOne + 'billing/stripe/create',
  billingStripePaymentListRequestDto: versionOne + 'billing/stripe/payment/list',
  billingStripePaymentStatusRefreshRequestDto: versionOne + 'billing/stripe/payment/refresh',

  // tracker
  trackerGenerateRequestDto: versionOne + 'tracker/generate',
};

export default API;
