import { Dispatch } from 'redux';

import { IGetState } from 'models/utils';

import API from 'constants/api';
import { request } from './utils';

export function dictionaryListRequest() {
  return (dispatch: Dispatch, getState: IGetState) => (
    request({ api: API.dictionaryListRequestDto, requestCancel: false })(dispatch, getState)
  );
}
