import types from 'actions/types';
import { cdeebeeHelpers } from '@recats/cdeebee';
import { fmtDate } from '@bidease/ui-kit';

import * as SummaryModel from 'models/summary';
import { EnumOrganizationStatsRevenueGroup } from 'models/objects';

import { normalizeUrlFilter } from '../normalize/ui';
import * as Enum from '../constants/enum';

export const INITIAL_STATE: SummaryModel.ISummaryReducer = {
  fromDate: normalizeUrlFilter.get(
    Enum.EnumFilterURLList.summary_fromDate,
    fmtDate.toUTCISOFromSettedTZ(fmtDate.startOfMonth(fmtDate.getZonedDate()))
  ),
  toDate: normalizeUrlFilter.get(
    Enum.EnumFilterURLList.summary_toDate,
    fmtDate.toUTCISOFromSettedTZ(fmtDate.endOfDay(fmtDate.getZonedDate()))
  ),

  useReklFigures: false, // static
  revenueGroupBy: EnumOrganizationStatsRevenueGroup.daily, // static
  countryIDList: normalizeUrlFilter.get(Enum.EnumFilterURLList.summary_countryIDList, []), // header summary
  organizationIDList: normalizeUrlFilter.get(Enum.EnumFilterURLList.summary_orgIDList, []), // header summary
  exchangeList: normalizeUrlFilter.get(Enum.EnumFilterURLList.summary_exchangeList, []), // header summary
  reklIDList: normalizeUrlFilter.get(Enum.EnumFilterURLList.summary_reklIDList, []), // header summary

  response: undefined,
};

export default function(state: SummaryModel.ISummaryReducer = INITIAL_STATE, action: any) {
  const { type, payload } = action;

  switch (type) {
    case types.SUMMARY_CHANGE_VALUE:
      return cdeebeeHelpers.batchingUpdate(state, payload.valueList);
    default:
      return state;
  }
}
