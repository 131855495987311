import Cookies from 'js-cookie';
import { Dispatch } from 'redux';
import { CdeebeeRequest, cdeebeeMergeStrategy } from '@recats/cdeebee';

import { EnumUserRole, HistoryLogListRequestDto, RawResponseDto, TrackerGenerateDtoEntity } from 'models/objects';
import { IGetState } from 'models/utils';

import { getSessionToken, getAdminSessionToken } from 'helpers/utils';
import { getActiveUISession, OmitDefaultRequestValue } from 'helpers/utils';

import API from 'constants/api';
import { EnumListName } from 'constants/enum';
import { defaultMergeListStrategy } from 'constants/enum';

export const initialCdeebee = new CdeebeeRequest(
  {
    data: {
      requestTzUtc: true,
      clientVersion: import.meta.env.REACT_APP_BUILD_VERSION,
      sessionToken: getSessionToken(),
      adminSessionToken: getAdminSessionToken(),
      browserTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  },
  {
    fileKey: 'file',
    bodyKey: 'value',
    mergeListStrategy: defaultMergeListStrategy,
  }
);

export const request = initialCdeebee.send;

export type IInitialCdeebee = {
  requestObject: { data: { sessionToken: string | undefined; adminSessionToken: string | undefined } };
};

export function userIsAdmin(getState: IGetState, userString: string, adminString: string): string {
  const { sessionList } = getState().cdeebee;
  const session = getActiveUISession(sessionList);
  if (session && session?.profile) {
    return session.profile && session.profile.userRole === EnumUserRole.administrator ? adminString : userString;
  }
  return userString;
}

export const setSessionToken = (sessionToken: string, expiresAt: string) => {
  Cookies.set('sessionToken', sessionToken, { expires: new Date(expiresAt) });
  (initialCdeebee as IInitialCdeebee).requestObject.data.sessionToken = sessionToken;
};

export function googleCitySearch(input: string, successFn: (data: any[]) => void) {
  const service = new google.maps.places.AutocompleteService();
  return () => (
    service.getQueryPredictions({ input }, (result: any, status: string) => {
      if (status === 'OK') {
        successFn(
          result.filter((q: any) => q.types && q.types.some((x: string) => x === 'locality' || x === 'administrative_area1'))
        );
      }
    })
  );
}

export function googleGetGeocode(placeId: string, successFn: (data: any[]) => void) {
  const geocoder = new google.maps.Geocoder();
  return () => (
    geocoder.geocode({ placeId }, (result: any, status: string) => {
      if (status === 'OK') {
        successFn(result[0]);
      }
    })
  );
}

export function historyLogListRequest(
  data: OmitDefaultRequestValue<HistoryLogListRequestDto>,
  mergeStrategy?: cdeebeeMergeStrategy
) {
  return (dispatch: Dispatch, getState: IGetState) => (
    request({
      api: API.historyLogListRequestDto,
      data,
      mergeListStrategy: {
        ...defaultMergeListStrategy,
        [EnumListName.historyLogList]: mergeStrategy || cdeebeeMergeStrategy.merge,
      },
    })(dispatch, getState)
  );
}

export function trackerGenerateRequest(
  trackerGenerateList: TrackerGenerateDtoEntity[],
  onSuccess: (v: TrackerGenerateDtoEntity[]) => void
) {
  return (dispatch: Dispatch, getState: IGetState) => (
    request({
      api: API.trackerGenerateRequestDto,
      data: { trackerGenerateList },
      updateStore: false,
      postUpdate(payload: { rawResponse: RawResponseDto }) {
        onSuccess(payload?.rawResponse?.trackerRawResponseList || []);
      },
    })(dispatch, getState)
  );
}
