import { Routes, Route } from 'react-router-dom';

import BaseRouter from 'components/BaseRouter';
import PrivateRouter from 'components/PrivateRouter';
//
import routes from 'constants/routes';
//
import { permissionObjectChecker } from 'helpers/permission';

import * as M from './imports';

export default () => (
  <Routes>
    <Route
      path={routes.login}
      element={<BaseRouter><M.Auth /></BaseRouter>}
    />
    <Route
      path={routes.serviceConfirmPassword}
      element={<BaseRouter><M.ServiceRestorePassword /></BaseRouter>}
    />
    <Route
      path={`${routes.serviceNoUser}/:errorCode`}
      element={<BaseRouter noRedirect={true}><M.ServiceNoResponseUser /></BaseRouter>}
    />
    <Route
      path={routes.billingStatus}
      element={<BaseRouter noRedirect={true}><M.ServiceBillingStatus /></BaseRouter>}
    />
    {/* */}

    {/* campaigns */}
    <Route
      path={routes.campaigns}
      element={<PrivateRouter><M.CampaignIndex /></PrivateRouter>}
    />
    <Route
      path={`${routes.campaignsCreate}/:menuState`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).campaigns.create}>
          <M.CampaignCreate />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.campaignsDetail}/:campaignIDList`}
      element={<PrivateRouter><M.CampaignDetail /></PrivateRouter>}
    />
    <Route
      path={`${routes.campaignsEdit}/:campaignID/:menuState`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).campaigns.campaignSettingsView}>
          <M.CampaignEdit />
        </PrivateRouter>
      }
    />
    {/* [[#END]] campaigns */}

    {/* creatives */}
    <Route
      path={routes.creatives}
      element={<PrivateRouter><M.CreativesIndex /></PrivateRouter>}
    >
      <Route
        path=':campaignID'
        element={<PrivateRouter><M.CreativesIndex /></PrivateRouter>}
      />
    </Route>
    <Route
      path={`${routes.creativesCreate}/:campaignID`}
      element={<PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).creatives.create}><M.CreativesCreate /></PrivateRouter>}
    />
    <Route
      path={`${routes.creativesEdit}/:creativeID`}
      element={<PrivateRouter><M.CreativesEdit /></PrivateRouter>}
    />
    <Route
      path={routes.creativesApprove}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).creatives.approveAction}>
          <M.CreativeApprove />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.creativesPreview}/:creativeID`}
      element={<PrivateRouter><M.CreativePreview /></PrivateRouter>}
    />
    {/* [[#END]] creatives */}

    {/* user */}
    <Route
      path={routes.userSettings}
      element={<PrivateRouter><M.UserSettings /></PrivateRouter>}
    />
    {/* [[#END]] user */}

    {/* Advertisers */}
    <Route
      path={routes.advertisers}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).advertisers.routerList}>
          <M.Advertisers />
        </PrivateRouter>
      }
    />
    <Route
      path={routes.advertiserCreate}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).advertisers.create}>
          <M.AdvertiserCreate />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.advertiser}/:reklID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).advertisers.routerDetail}>
          <M.AdvertisersDetail />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Advertisers */}

    {/* Markup */}
    <Route
      path={routes.markups}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).markups.routerList}>
          <M.Markups />
        </PrivateRouter>
      }
    />
    <Route
      path={routes.markupsCreate}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).markups.create}>
          <M.MarkupsCreate />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.markupsEdit}/:markupID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).markups.edit}>
          <M.MarkupsEdit />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Markup */}

    {/* Segment */}
    <Route
      path={routes.segments}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).segments.view}>
          <M.Segments />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Segment */}

    {/* Reports */}
    <Route
      path={routes.reports}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).reports.routerList}>
          <M.Reports />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Reports */}

    {/* History */}
    <Route
      path={`${routes.history}/:type/:elementID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).history.routerList}>
          <M.History />
        </PrivateRouter>
      }
    />
    {/* [[#END]] History */}

    {/* Model */}
    <Route
      path={routes.modelTasks}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).models.modelTasksPage}>
          <M.ModelTasks />
        </PrivateRouter>
      }
    >
      <Route
        path=':bundleID'
        element={
          <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).models.modelTasksPage}>
            <M.ModelTasks />
          </PrivateRouter>
        }
      />
    </Route>
    {/* [[#END]] Model */}

    {/* Product */}
    <Route
      path={`${routes.productEdit}/:productID/:menuState`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).product.edit}>
          <M.ProductEdit />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.productCreate}/:menuState`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).product.create}>
          <M.ProductCreate />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Product */}

    {/* Organization */}
    <Route
      path={routes.organizations}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).organization.organizationList}>
          <M.OrganizationIndex />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.organizationsEdit}/:organizationID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).organization.organizationEdit}>
          <M.OrganizationShow />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.organizationsView}/:organizationID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).organization.organizationView}>
          <M.OrganizationShow />
        </PrivateRouter>
      }
    />
    <Route
      path={routes.organizationCreate}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).organization.organizationCreate}>
          <M.OrganizationCreate />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Organization */}

    {/* Bundles */}
    <Route
      path={routes.bundles}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).bundles.menu}>
          <M.Bundles />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.bundleCreate}/:reklID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).bundles.menu}>
          <M.BundleCreate />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Bundles */}

    {/* Advertiser Bundle */}
    <Route
      path={`${routes.advertiserBundle}/:reklBundleID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).reklBundles.menu}>
          <M.AdvertiserBundleIndex />
        </PrivateRouter>
      }
    />
    <Route
      path={`${routes.advertiserBundleEdit}/:reklBundleID`}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).reklBundles.edit}>
          <M.AdvertiserBundleEdit />
        </PrivateRouter>
      }
    />
    {/* [[#END]] Advertiser Bundle */}

    {/*Billing*/}
    <Route
      path={routes.billing}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).billing.billingPage}>
          <M.BillingIndex />
        </PrivateRouter>
      }
    />
    {/*[[#END]] Billing*/}

    {/*Summary*/}
    <Route
      path={routes.summary}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).summary.view}>
          <M.SummaryIndex />
        </PrivateRouter>
      }
    />
    {/*[[#END]] Summary*/}

    <Route
      path={routes.usersManager}
      element={
        <PrivateRouter permissionChecker={profile => permissionObjectChecker(profile).user.routerManager}>
          <M.UsersManager />
        </PrivateRouter>
      }
    />

    <Route
      path={'/*'}
      element={<PrivateRouter><M.NotFound /></PrivateRouter>}
    />
  </Routes>
);
