import { fmtDate, fmtNumber } from '@bidease/ui-kit';
import { cdeebeeMergeStrategy } from '@recats/cdeebee';

import { EnumFieldColumnListType, EnumListType } from 'models/objects';
import localize from './localize';

export const Service = {
  email: 'mail@bidease.com',
};

export enum EnumCssVars {
  tableLeftCol = '--table-left-col',
  tableTransformOnFixed = '--table-transform-on-fixed',
  globalNoDisablePreloader = '--global-no-disabled-preloader',
}

export enum EnumLocalStorageKey {
  useReklFigures = 'use-rekl-figures',
  performanceChartConfig = 'performance-chart-config_v2',
}

export enum EnumListName {
  sessionList = 'sessionList',
  rejectList = 'rejectList',
  mLearnTaskList = 'mLearnTaskList',
  predictorModelList = 'predictorModelList',
  segmentList = 'segmentList',
  organizationList = 'organizationList',
  campaignList = 'campaignList',
  productList = 'productList',
  userList = 'userList',
  reklList = 'reklList',
  countryList = 'countryList',
  exchangeList = 'exchangeList',
  connectionTypeList = 'connectionTypeList',
  languageList = 'languageList',
  operationSystemVersionList = 'operationSystemVersionList',
  sspList = 'sspList',
  iabCategoryList = 'iabCategoryList',
  creativeList = 'creativeList',
  userAgentList = 'userAgentList',
  markupList = 'markupList',
  reportTaskPrototypeList = 'reportTaskPrototypeList',
  cityList = 'cityList',
  zipList = 'zipList',
  applicationList = 'applicationList',
  reportTaskList = 'reportTaskList',
  historyLogList = 'historyLogList',
  resourceList = 'resourceList',
  exchangeLossReasonList = 'exchangeLossReasonList',
  trackerList = 'trackerList',
  reklBundleList = 'reklBundleList',
  bundleList = 'bundleList',
  organizationPaymentList = 'organizationPaymentList',
  genreList = 'genreList',
  timeZoneList = 'timeZoneList',
}

export enum EnumRawResponseListName {
  bundleList = 'bundleList',
  reklBundleList = 'reklBundleList',
  reklList = 'reklList',
  organizationPredictorResponse = 'organizationPredictorResponse',
}

export enum ModalFragmentHASH {
  appNameKeywords = 'appNameKeywords',
  applicationList = 'applicationList',
  installedApplicationBlackList = 'installedApplicationBlackList',
  installedApplicationGroupWhiteList = 'installedApplicationGroupWhiteList',
  carrierList = 'carrierList',
  cityList = 'cityList',
  connectionTypeList = 'connectionTypeList',
  countryList = 'countryList',
  exchangeList = 'exchangeList',
  iabCategoryList = 'iabCategoryList',
  ipRangeList = 'ipRangeList',
  languageList = 'languageList',
  makeList = 'makeList',
  modelList = 'modelList',
  genreList = 'genreList',
  operationSystemVersionList = 'operationSystemVersionList',
  regionList = 'regionList',
  sspList = 'sspList',
  userAgentList = 'userAgentList',
  ipList = 'ipList',
  zipList = 'zipList',
  markupVariableList = 'markupVariableList',
  bindingList = 'bindingList',
  customTags = 'customTags',
  customBidCPMList = 'customBidCPMList',
}

export const defaultMergeListStrategy = {
  [EnumListName.sessionList]: cdeebeeMergeStrategy.replace,
};

export const BideaseMail = 'support@bidease.com';

export const EnumOrganizationID = {
  Embria: 82,
};

export const EnumRetentionDays = [
  { id: 'ret_1', value: null, label: 'None' },
  { id: 'ret_2', value: 1, label: '1 day' },
  { id: 'ret_3', value: 2, label: '2 days' },
  { id: 'ret_4', value: 3, label: '3 days' },
  { id: 'ret_5', value: 7, label: '7 days' },
  { id: 'ret_6', value: 14, label: '14 days' },
  { id: 'ret_7', value: 30, label: '30 days' },
];

export const EnumRoasDays = [
  { id: 'roas_1', value: null, label: 'None' },
  { id: 'roas_2', value: 1, label: '1 day' },
  { id: 'roas_3', value: 2, label: '2 days' },
  { id: 'roas_4', value: 3, label: '3 days' },
  { id: 'roas_5', value: 7, label: '7 days' },
  { id: 'roas_6', value: 14, label: '14 days' },
  { id: 'roas_7', value: 30, label: '30 days' },
  { id: 'roas_8', value: 90, label: '90 days' },
  { id: 'roas_9', value: 0, label: 'All time' },
];

export const CampaignTargetingImpressionPosition = [
  { value: 0, label: 'No' },
  { value: 3600, label: '1 hour' },
  { value: 3 * 3600, label: '3 hours' },
  { value: 12 * 3600, label: '12 hours' },
  { value: 24 * 3600, label: '24 hours' },
];

export enum EnumInfoType {
  warning = 'warning',
  error = 'error',
  message = 'message',
  inProgress = 'in progress',
  success = 'success',
}

export const EnumLegalEntity = {
  Personal: { value: 'Personal', label: 'Personal' },
  Corp: { value: 'Corp', label: 'Corp' },
  LLC: { value: 'LLC', label: 'LLC' },
  Limited: { value: 'Limited', label: 'Limited' },
  Other: { value: 'Other', label: 'Other...' },
};

export enum EnumMaxFileSize {
  mb100 = 1e+8,
}

export const EnumAccessType = {
  image: { 'image/*': ['.jpeg', '.png'] },
  video: { 'video/*': ['.mp4'] },
  all: { '*/*': [] },
};

export const EnumPaginationSize = 10;
export const EnumItemPerPage = 50;
export const CountServerResponse = 24;
export const EnumCountServerResponseDefault = 50;

export enum EnumDebounce {
  never = 0,
  zero = 500,
  minimal = 1000,
  default = 2000,
  resetForm = 2200,
}

export enum EnumOrientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
}

export enum EnumFilterURLList {
  fromDate = 'fromDate',
  toDate = 'toDate',

  // NOTE: only manual change
  nameContains = 'nameContains',
  retentionDays = 'retentionDays',
  roasDays = 'roasDays',

  listOrdering_orderingKey = 'lo_ordKey',
  listOrdering_orderingDirection = 'lo_ordDirection',

  statsOrdering_orderingKey = 'so_ordKey',
  statsOrdering_orderingDirection = 'so_ordDirection',
  statsGrouping_groupingKeyList = 'so_groupKeyList',

  statsFilter_campaignIDList = 'sf_campaignID',
  statsFilter_campaignStates = 'sf_states',
  statsFilter_reklIDList = 'sf_reklID',
  statsFilter_managerIDList = 'sf_managerID',
  statsFilter_filterList = 'sf_filterList',
  statsFilter_productList = 'sf_productList',
  statsFilter_attributionType = 'sf_attType',
  statsFilter_archivedCampaign = 'sf_archivedCampaign',
  statsFilter_nativeProduct = 'sf_nativeProduct',
  statsFilter_targetingContainerState = 'sf_targetingContainerState',

  cmpGraphOpen = 'cmpGraphOpen',

  page = 'page',
  count = 'count',

  // summary page
  summary_fromDate = 'sm_fromDate',
  summary_toDate = 'sm_toDate',
  summary_orgIDList = 'sm_orgID',
  summary_exchangeList = 'sm_exchange',
  summary_countryIDList = 'sm_countryID',
  summary_reklIDList = 'sm_reklID'
}

export const EnumWeekDay = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const EnumFormattingTableType = {
  [EnumFieldColumnListType.listType]: {
    [EnumListType.exactAmount]: (val: number) => fmtNumber.amountWithCurrency(val, 0),
    [EnumListType.exactPercent]: (val: number) => fmtNumber.exactPercent(val, 4),
    [EnumListType.date]: fmtDate.date,
    [EnumListType.amount]: fmtNumber.amountWithCurrency,
    [EnumListType.number]: fmtNumber.default,
    [EnumListType.percent]: fmtNumber.percent,
    [EnumListType.default]: (val: string | number) => val,
    [EnumListType.bidCPM]: fmtNumber.amountBidCPM,
    [EnumListType.roundedNumber]: fmtNumber.amountLargeFormat,
    [EnumListType.ignore]: () => localize.NullableValue,
  },
  [EnumFieldColumnListType.totalType]: {
    [EnumListType.exactAmount]: (val: number) => fmtNumber.amountWithCurrency(val, 0),
    [EnumListType.exactPercent]: (val: number) => fmtNumber.exactPercent(val, 4),
    [EnumListType.date]: fmtDate.date,
    [EnumListType.amount]: fmtNumber.amountWithCurrency,
    [EnumListType.number]: fmtNumber.default,
    [EnumListType.percent]: fmtNumber.percent,
    [EnumListType.default]: (val: string | number) => val,
    [EnumListType.bidCPM]: fmtNumber.amountBidCPM,
    [EnumListType.roundedNumber]: fmtNumber.amountLargeFormat,
    [EnumListType.ignore]: () => localize.NullableValue,
  },
};

export enum EnumOrganizationPredictorTimePreset {
  oneTenthHour = 'oneTenthHour',
  oneHour = 'oneHour',
  day = 'day',
}

export const UniqFormKeyID = '_form_id';
export type EnumUniqFormKeyID = typeof UniqFormKeyID;
